import type { Dispatch, FunctionComponent, Reducer, ReactNode } from 'react'
import { useContext, useReducer, createContext } from 'react'
import { CardModel } from '~/services/card'
import type { Card } from '~/services/card'
import { CardResponse, CardStatus } from '~/services/dto/ts/card'

interface Props {
  children: ReactNode
  initialState: CardStoreState
}

export interface CardStoreState {
  card: CardModel
}

export type Action =
  | { type: 'set_card'; card: Card }
  | { type: 'clear_card' }
  | { type: 'mark_card_as_active' }

interface CardStoreContext {
  state: CardStoreState
  dispatch: Dispatch<Action>
}

export const DEFAULT_CARD_STATE: CardStoreState = {
  card: new CardModel({
    user_id: '',
    brand_id: '',
    card_id: '',
    account_id: '',
    masked_card_number: '',
    name_on_card: '',
    card_type: CardResponse.card_type.PRIMARY,
    instrument_type: CardResponse.instrument_type.PHYSICAL,
    expiration: '',
    card_status: {
      status: CardStatus.status.ACTIVE,
    },
    cvv: '',
    created_at: '',
  }),
}

export const reducer: Reducer<CardStoreState, Action> = (state, action) => {
  switch (action.type) {
    case 'set_card': {
      return {
        ...state,
        card: new CardModel(action.card),
      }
    }
    case 'mark_card_as_active': {
      const newCard: Card = {
        ...state.card,
        card_status: {
          status: CardStatus.status.ACTIVE,
        },
      }
      return {
        ...state,
        card: new CardModel(newCard),
      }
    }
    case 'clear_card': {
      return {
        ...state,
        card: new CardModel(DEFAULT_CARD_STATE.card),
      }
    }
  }
}

const CardStoreContext = createContext<CardStoreContext>({
  state: DEFAULT_CARD_STATE,
  dispatch: () => {},
})

export const CardStoreProvider: FunctionComponent<Props> = ({
  children,
  initialState = DEFAULT_CARD_STATE,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <CardStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </CardStoreContext.Provider>
  )
}

export const useCardStore = (): CardStoreContext => {
  return useContext(CardStoreContext)
}
