/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CardProduct } from './CardProduct';
export type BrandProgramRestricted = {
    brand_id: string;
    brand_program_id: number;
    program_base_url: string;
    program_shortcode: string;
    program_username: string;
    bundle_token: string;
    program_type: BrandProgramRestricted.program_type;
    card_products?: Array<CardProduct>;
    enabled_feature_flags?: Array<'ACCOUNT_ROLES' | 'REWARDS' | 'CARD_BENEFITS_BOOKLET' | 'UNKNOWN'>;
};
export namespace BrandProgramRestricted {
    export enum program_type {
        CONSUMER = 'CONSUMER',
        SMB = 'SMB',
    }
}

