/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CardProduct } from './CardProduct';
export type BrandProgram = {
    brand_program_id: number;
    brand_id: string;
    program_base_url: string;
    program_shortcode: string;
    program_username: string;
    bundle_token: string;
    webhook_secret: string;
    card_products?: Array<CardProduct>;
    program_type: BrandProgram.program_type;
    enabled_feature_flags?: Array<'ACCOUNT_ROLES' | 'REWARDS' | 'CARD_BENEFITS_BOOKLET' | 'UNKNOWN'>;
    webhook_password?: string;
    alloy_config_id: number;
    id: number;
};
export namespace BrandProgram {
    export enum program_type {
        CONSUMER = 'CONSUMER',
        SMB = 'SMB',
    }
}

