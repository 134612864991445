/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CardResponse } from './CardResponse';
export type AccountDetailsResponse = {
    account_id: string;
    primary_card_id?: string;
    payment_due_date: string;
    minimum_payment_due: number;
    credit_limit: number;
    available_credit: number;
    current_balance: number;
    available_balance: number;
    remaining_statement_balance: number;
    total_outstanding_balance: number;
    days_delinquent: number;
    cards: Array<CardResponse>;
    status?: AccountDetailsResponse.status;
};
export namespace AccountDetailsResponse {
    export enum status {
        UNACTIVATED = 'UNACTIVATED',
        ACTIVE = 'ACTIVE',
        SUSPENDED = 'SUSPENDED',
        TERMINATED = 'TERMINATED',
        CHARGE_OFF = 'CHARGE_OFF',
    }
}

