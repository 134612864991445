/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VelocityControl } from './VelocityControl';
export type SecondaryCardRequest = {
    /**
     * ID of the user to issue an additional card to
     */
    user_id: string;
    instrument_type: SecondaryCardRequest.instrument_type;
    velocity_control?: VelocityControl;
};
export namespace SecondaryCardRequest {
    export enum instrument_type {
        VIRTUAL = 'VIRTUAL',
        PHYSICAL = 'PHYSICAL',
    }
}

